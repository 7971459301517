import { Form } from '../../../types/ambassador/bookings/ambassador-services-catalog';
import { ServerErrorType } from '../../../types/errors';
import { FormApiContext, withErrorBoundary } from '../utils';
import { get as getForm } from '@wix/ambassador-bookings-v1-form/http';

export const DefaultFormID = '00000000-0000-0000-0000-000000000000';

type getDefaultFormArgs = {};

export const getDefaultForm = async ({
  httpClient,
  reportError,
}: getDefaultFormArgs & FormApiContext): Promise<Form | undefined> => {
  const { data } = await withErrorBoundary(
    {
      fn: async () => {
        return httpClient.request(
          getForm({
            id: DefaultFormID,
          }),
        );
      },
      mapError: (e) => ({
        error: ServerErrorType.FAILED_TO_FETCH_DEFAULT_FORM_DATA,
        shouldReport: true,
      }),
      fallback: undefined,
    },
    reportError,
  );

  return data?.data?.form as Form;
};
