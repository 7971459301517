import { ServerErrorType } from '../../../types/errors';
import { FormSelectedSlot } from '../../../types/formSelectedSlots';
import { BusinessInfo, IncompleteFormSelectedSlot } from '../../../types/types';
import { mapBusinessResponseToBusinessInfo } from '../../../utils/mappers/businessInfo.mapper';
import { CatalogData, OnError } from '../../types';
import { FormApiContext, withErrorBoundary } from '../utils';
import {
  BulkRequest,
  GetActiveFeaturesResponse,
  ListServicesResponse,
} from '@wix/ambassador-services-catalog-server/http';
import { mapCatalogResourceResponseToStaffMember } from '@wix/bookings-uou-mappers';

type GetCatalogDataArgs = {
  formSelectedSlot?: FormSelectedSlot;
  onError?: OnError;
};

export const getCatalogData = async ({
  catalogServer,
  reportError,
  authorization,
  formSelectedSlot,
  onError,
}: GetCatalogDataArgs & FormApiContext): Promise<CatalogData> => {
  const servicesCatalogService = catalogServer.Bulk();
  const bulkRequest: BulkRequest = createBulkRequest({
    formSelectedSlot,
  });
  const { data, error } = await withErrorBoundary(
    {
      fn: async () => {
        const catalogData = await servicesCatalogService({
          Authorization: authorization,
        }).get(bulkRequest);
        const listResponse: ListServicesResponse =
          catalogData.responseServices!;
        const businessInfo: BusinessInfo = mapBusinessResponseToBusinessInfo(
          catalogData.responseBusiness!,
        );

        const activeFeatures: GetActiveFeaturesResponse =
          catalogData.responseBusiness!.activeFeatures!;

        const serviceList = listResponse?.services!.map((service) => {
          const slot = formSelectedSlot?.nestedSlots.find(
            (nestedSlot) => nestedSlot.serviceId === service?.service?.id,
          );
          const serviceResourcesIds =
            service?.resources?.map((resource) => resource.id) || [];
          const relevantResources =
            catalogData.responseResources!.resources!.filter(
              (resource) =>
                slot?.resource?.id ||
                serviceResourcesIds.includes(resource.resource!.id),
            );
          const staffMembers = relevantResources.map(
            mapCatalogResourceResponseToStaffMember,
          );

          return {
            service,
            staffMembers,
          };
        });

        return {
          serviceList,
          businessInfo,
          activeFeatures,
        };
      },
      mapError: (e) => ({
        error: ServerErrorType.INVALID_CATALOG_DATA,
        shouldReport: true,
      }),
      fallback: undefined,
    },
    reportError,
  );

  if (error) {
    onError?.(error);
  }

  return data!;
};

const createBulkRequest = ({
  formSelectedSlot,
}: {
  formSelectedSlot?: IncompleteFormSelectedSlot;
  isPreview?: boolean;
}): BulkRequest => {
  const filter: { [key: string]: any } = {};
  let isMissingResource = false;
  const resourceIds =
    formSelectedSlot?.nestedSlots.map((slot) => {
      if (!slot.resource?.id) {
        isMissingResource = true;
      }
      return slot.resource?.id;
    }) || [];

  if (isMissingResource || resourceIds.length === 0) {
    filter['resource.tags'] = { $hasSome: ['staff'] };
  } else {
    filter['resource.id'] = { $in: resourceIds };
  }

  const serviceIds = formSelectedSlot?.nestedSlots
    .map((slot) => slot.serviceId)
    .filter((slot) => slot);

  return {
    ...(serviceIds?.length
      ? {
          requestServices: {
            includeDeleted: false,
            query: {
              filter: JSON.stringify({
                'service.id': {
                  $in: serviceIds,
                },
              }),
            },
          },
        }
      : {}),
    requestListResources: {
      includeDeleted: true,
      query: {
        filter: JSON.stringify(filter),
        fields: ['resource.id', 'resource.name'],
        fieldsets: [],
        paging: {
          limit: 1000,
        },
        sort: [],
      },
    },
    requestBusiness: {
      suppressNotFoundError: false,
    },
  };
};
