import { createSlotVeloAPIFactory } from '@wix/widget-plugins-ooi/velo';
import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import { InitialTpaPlugins } from '../../types';
import { AdditionalTimeSlot$w, SLOT_NAME } from '../types';

export class AdditionalBookings {
  private additionalTimeSlot$w: AdditionalTimeSlot$w & {
    isMethodSupported: (methodName: string) => boolean;
  };
  private context: InitialTpaPlugins;

  constructor(context: InitialTpaPlugins) {
    const slotAPIFactory = createSlotVeloAPIFactory(context.controllerConfig);

    this.context = context;
    this.additionalTimeSlot$w = slotAPIFactory.getSlotAPI(SLOT_NAME) as any;
  }

  async runListeners() {
    if (this.additionalTimeSlot$w.isMethodSupported('onAddBookings')) {
      this.additionalTimeSlot$w.onAddBookings(this.onAddBookings);
    }
  }

  async onAddBookings(bookingIds: string[]) {
    console.log('booking ids got from plugin', bookingIds);
    // await this.context.actions.addAdditionalSlot(additionalTimeSlotOutput);
  }

  static setBookingIds(
    bookingIds: string[],
    controllerConfig: IWidgetControllerConfig,
  ) {
    const slotAPIFactory = createSlotVeloAPIFactory(controllerConfig);
    const slotAPI = slotAPIFactory.getSlotAPI(SLOT_NAME) as any;

    slotAPI.bookingIds = bookingIds;
  }
}
